import React, { useEffect, useRef, useState } from "react";
import { Form, Col, Row, Spinner, Image, FormText } from "react-bootstrap";
import { Helmet } from "react-helmet";
import CardComponent from "../../components/CardComponent";
import { Formik } from "formik";
import {
  getAdminDistrictOnSaId,
  getAdminEnergyCompanyassignZone,
  getAllEneryComnies,
  getRoOnZoneId,
  getSalesOnRoId,
} from "../../services/authapi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  getAllOutlet,
  getAllOutletById,
  postOutlet,
  updateOutlet,
} from "../../services/contractorApi";
import { toast } from "react-toastify";
import ConfirmAlert from "../../components/ConfirmAlert";
import ImageViewer from "../../components/ImageViewer";
import ViewOutlet from "./ViewOutlet";
import { useTranslation } from "react-i18next";
import { addOutletsSchema, updateOutletsSchema } from "../../utils/formSchema";
import MyInput from "../../components/MyInput";
import { getImageSrc } from "../../utils/helper";
import { X } from "lucide-react";

const CreateOutlet = ({ checkPermission }) => {
  const [outletdata, setOutletData] = useState([]);
  const [edit, setEdit] = useState({});
  const [allEnergy, setAllEnergy] = useState([]);
  const [allZones, setAllZones] = useState([]);
  const [allRo, setAllRo] = useState([]);
  const [allSa, setAllSa] = useState([]);
  const [allDistrict, setAllDistrict] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") || null;

  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const image_file_ref = useRef(null);

  const fetchAllOutlet = async () => {
    const res = await getAllOutlet();
    if (res.status) {
      setOutletData(res.data);
    } else {
      setOutletData([]);
    }
  };

  const fetchOutletById = async () => {
    const res = await getAllOutletById(id);
    if (res.status) {
      setEdit(res.data);
      fetchZoneData(res.data.energy_company_id);
      fetchRoData(res.data.zone_id);
      fetchSaData(res.data.regional_office_id);
      fetchDistrictData(res.data.sales_area_id);
    } else {
      setEdit([]);
    }
  };

  const fetchEnergyCompanyData = async () => {
    const res = await getAllEneryComnies();
    if (res.status) {
      const rData = res.data.map((data) => {
        return {
          value: data.energy_company_id,
          label: data.name,
        };
      });
      setAllEnergy(rData);
    } else {
      setAllEnergy([]);
    }
  };

  const fetchZoneData = async (value) => {
    const res = await getAdminEnergyCompanyassignZone(value);
    if (res.status) {
      const rData = res.data.map((data) => {
        return {
          value: data.zone_id,
          label: data.zone_name,
        };
      });
      setAllZones(rData);
    } else {
      setAllZones([]);
    }
  };

  const fetchRoData = async (value) => {
    const res = await getRoOnZoneId(value);

    if (res.status) {
      const rData = res.data.map((data) => {
        return {
          value: data.ro_id,
          label: data.regional_office_name,
        };
      });
      setAllRo(rData);
    } else {
      setAllRo([]);
    }
  };

  const fetchSaData = async (value) => {
    const res = await getSalesOnRoId(value);

    if (res.status) {
      const rData = res.data.map((data) => {
        return {
          value: data.id,
          label: data.sales_area_name,
        };
      });
      setAllSa(rData);
    } else {
      setAllSa([]);
    }
  };

  const fetchDistrictData = async (value) => {
    const res = await getAdminDistrictOnSaId(value);
    if (res.status) {
      const rData = res.data.map((data) => {
        return {
          value: data.district_id,
          label: data.district_name,
        };
      });
      setAllDistrict(rData);
    } else {
      setAllDistrict([]);
    }
  };

  const getImageUrl = (file) => {
    const type = file.name ? "new" : "edit";
    if (type == "new") {
      const url = URL.createObjectURL(file);
      return url;
    } else {
      const url = `${process.env.REACT_APP_API_URL}${file}`;
      return url;
    }
  };

  useEffect(() => {
    if (id !== "new") {
      fetchOutletById();
    }
    fetchEnergyCompanyData();
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // console.log("values --->", values);

    const formData = new FormData();
    formData.append("energy_company_id", values.energy_company_id);
    formData.append("zone_id", values.zone_id);
    formData.append("regional_id", values.regional_id);
    formData.append("sales_area_id", values.sales_area_id);
    formData.append("district_id", values.district_id);
    formData.append("outlet_name", values.outlet_name);
    formData.append("outlet_unique_id", values.outlet_unique_id);
    formData.append("outlet_contact_number", values.outlet_contact_number);
    formData.append("customer_code", values.customer_code);
    formData.append("outlet_category", values.outlet_category);
    formData.append("outlet_ccnoms", values.outlet_ccnoms);
    formData.append("outlet_ccnohsd", values.outlet_ccnohsd);
    formData.append(
      "outlet_contact_person_name",
      values.outlet_contact_person_name
    );
    formData.append("primary_number", values.primary_number);
    formData.append("secondary_number", values.secondary_number);
    formData.append("primary_email", values.primary_email);
    formData.append("secondary_email", values.secondary_email);
    formData.append("location", values.location);
    formData.append("address", values.address);
    formData.append("outlet_resv", values.outlet_resv);
    formData.append("outlet_longitude", values.outlet_longitude);
    formData.append("outlet_lattitude", values.outlet_lattitude);
    formData.append("status", 1);
    formData.append("image", values.image);
    formData.append("email", values.email);
    formData.append("password", values.password);

    if (edit.id) {
      formData.append("id", edit.id);
    }

    // return console.log(formData, "formdata");
    const res = edit?.id
      ? await updateOutlet(formData)
      : await postOutlet(formData);

    if (res.status) {
      toast.success(res.message);
      navigate("/fuel-station?tab=0");
      resetForm();
    } else {
      toast.error(res.message);
    }
    setSubmitting(false);
    setShowAlert(false);
  };

  return (
    <>
      <Helmet>
        <title>Create Outlet Management · CMS Electricals </title>
      </Helmet>
      <Col md={12} data-aos={"fade-up"} data-aos-delay={200}>
        <CardComponent
          title={`${
            type === "view" ? "View" : edit?.id ? "Update" : "Create"
          } Outlet`}
          className={type === "view" && "after-bg-light"}
        >
          <Formik
            enableReinitialize={true}
            initialValues={{
              energy_company_id: edit.energy_company_id || "",
              zone_id: edit.zone_id || "",
              regional_id: edit.regional_office_id || "",
              sales_area_id: edit.sales_area_id || "",
              district_id: edit.district_id || "",
              outlet_name: edit.outlet_name || "",
              password: edit.password || "",
              outlet_unique_id: edit.outlet_unique_id || "",
              outlet_contact_number: edit.outlet_contact_number || "",
              customer_code: edit.customer_code || "",
              outlet_category: edit.outlet_category || "",
              outlet_ccnoms: edit.outlet_ccnoms || "",
              outlet_ccnohsd: edit.outlet_ccnohsd || "",
              outlet_contact_person_name: edit.outlet_contact_person_name || "",
              primary_number: edit.primary_number || "",
              secondary_number: edit.secondary_number || "",
              primary_email: edit.primary_email || "",
              secondary_email: edit.secondary_email || "",
              location: edit.location || "",
              address: edit.address || "",
              outlet_resv: edit.outlet_resv || "",
              outlet_longitude: edit.outlet_longitude || "",
              outlet_lattitude: edit.outlet_lattitude || "",
              image: edit.outlet_image || "",
              email: edit.email || "",
              password: edit.password || "",
            }}
            validationSchema={edit.id ? updateOutletsSchema : addOutletsSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form onSubmit={props?.handleSubmit}>
                <Row className="g-3">
                  {type === "view" ? (
                    <ViewOutlet edit={edit} />
                  ) : (
                    <>
                      <Form.Group as={Col} md={4}>
                        <MyInput
                          isRequired
                          label={t("Energy Company")}
                          name={"energy_company_id"}
                          formikProps={props}
                          customType={"select"}
                          selectProps={{
                            data: allEnergy,
                            value: props.values.energy_company_id,
                            onChange: (e) => {
                              fetchZoneData(e?.value);
                              props.setFieldValue("energy_company_id", "");
                            },
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          isRequired
                          label={t("Zone")}
                          name={"zone_id"}
                          formikProps={props}
                          customType={"select"}
                          allowMultipleFile={true}
                          selectProps={{
                            data: allZones,
                            value: props.values.zone_id,
                            onChange: (e) => {
                              fetchRoData(e?.value);
                              props.setFieldValue("zone_id", "");
                            },
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          isRequired
                          label={t("Regional Office")}
                          name={"regional_id"}
                          formikProps={props}
                          customType={"select"}
                          selectProps={{
                            data: allRo,
                            value: props.values.regional_id,
                            onChange: (e) => {
                              fetchSaData(e?.value);
                              props.setFieldValue("regional_id", "");
                            },
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          isRequired
                          name={"sales_area_id"}
                          formikProps={props}
                          customType={"select"}
                          label={t("Sales Area")}
                          selectProps={{
                            data: allSa,
                            value: props.values.sales_area_id,
                            onChange: (e) => {
                              fetchDistrictData(e?.value);
                              props.setFieldValue("sales_area_id", "");
                            },
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          name={"district_id"}
                          label={t("District")}
                          formikProps={props}
                          customType={"select"}
                          selectProps={{
                            data: allDistrict,
                            value: props.values.district_id,
                            onChange: (e) => {
                              props.setFieldValue("district_id", "");
                            },
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          isRequired
                          name={"outlet_unique_id"}
                          label={t("Outlet Unique Id")}
                          formikProps={props}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          isRequired
                          name={"outlet_name"}
                          label={t("Outlet Name")}
                          formikProps={props}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          isRequired
                          name={"outlet_contact_number"}
                          label={t("Outlet Contact Number")}
                          formikProps={props}
                          customType={"phone"}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          isRequired
                          name={"customer_code"}
                          label={t("Customer Code")}
                          formikProps={props}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          isRequired
                          name={"outlet_category"}
                          label={t("Outlet Category")}
                          formikProps={props}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          isRequired
                          name={"outlet_ccnoms"}
                          label={t("Outlet CCNOMS")}
                          formikProps={props}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          isRequired
                          name={"outlet_ccnohsd"}
                          label={t("Outlet CCNOHSD")}
                          formikProps={props}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <Form.Label id="image">{t("image")}</Form.Label>

                        <div className="d-flex gap-2">
                          <Form.Control
                            type="file"
                            name={"image"}
                            ref={image_file_ref}
                            accept="image/png, image/jpeg, image/jpg, image/jfif"
                            onChange={(e) =>
                              props.setFieldValue("image", e.target.files[0])
                            }
                          />
                          <ImageViewer
                            downloadIcon
                            href={getImageSrc(props.values.image, edit?.image)}
                            src={getImageSrc(props.values.image, edit?.image)}
                          >
                            <img
                              src={getImageSrc(props.values.image, edit?.image)}
                              className="my-btn object-fit"
                            />
                          </ImageViewer>
                          {props.values.image && (
                            <X
                              onClick={() => {
                                props.setFieldValue("image", "");
                                if (image_file_ref.current) {
                                  image_file_ref.current.value = "";
                                }
                              }}
                              className="my-btn"
                            />
                          )}
                        </div>
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          name={"outlet_contact_person_name"}
                          label={t("Outlet Person Name")}
                          formikProps={props}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          name={"primary_number"}
                          label={t("Primary Number")}
                          formikProps={props}
                          customType={"phone"}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          name={"secondary_number"}
                          label={t("Secondary Number")}
                          formikProps={props}
                          customType={"phone"}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          name={"primary_email"}
                          label={t("Primary Email")}
                          formikProps={props}
                          type="email"
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          name={"secondary_email"}
                          label={t("Secondary Email")}
                          formikProps={props}
                          type="email"
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          name={"location"}
                          label={t("Location")}
                          formikProps={props}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          name={"address"}
                          label={t("Address")}
                          formikProps={props}
                          customType={"multiline"}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          name={"outlet_resv"}
                          label={t("Outlet RESV")}
                          formikProps={props}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          name={"outlet_longitude"}
                          label={t("Outlet Longitude")}
                          formikProps={props}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <MyInput
                          name={"outlet_lattitude"}
                          label={t("Outlet Lattitude")}
                          formikProps={props}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={12}>
                        <div className="shadow mt-2 p-3">
                          <Form.Group as={Row} className="mb-3">
                            <Form.Label column>
                              {t("User Email")}{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Col sm={8}>
                              <Form.Control
                                type="email"
                                value={props.values.email}
                                onChange={props.handleChange}
                                name={"email"}
                                onBlur={props.handleBlur}
                                isInvalid={Boolean(
                                  props.touched.email && props.errors.email
                                )}
                              />
                              <Form.Control.Feedback type="invalid">
                                {props.errors.email}
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-3">
                            <Form.Label column>
                              {t("Password")}{" "}
                              {edit?.id ? null : (
                                <span className="text-danger">*</span>
                              )}
                            </Form.Label>
                            <Col sm={8}>
                              <div className="d-grid gap-1">
                                <Form.Control
                                  type="password"
                                  value={props.values.password}
                                  onChange={props.handleChange}
                                  name="password"
                                  onBlur={props.handleBlur}
                                  isInvalid={Boolean(
                                    props.touched.password &&
                                      props.errors.password
                                  )}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {props.errors.password}
                                </Form.Control.Feedback>
                                {edit?.id ? (
                                  <FormText>
                                    Password is encrypted. If you don't want to
                                    change it, leave it blank.
                                  </FormText>
                                ) : null}
                              </div>
                            </Col>
                          </Form.Group>
                        </div>
                      </Form.Group>

                      <Col md={12}>
                        <div className="mt-4 text-center">
                          <button
                            type={`${edit.id ? "button" : "submit"}`}
                            onClick={() => setShowAlert(edit.id && true)}
                            disabled={props?.isSubmitting}
                            className="shadow border-0 purple-combo cursor-pointer px-4 py-1"
                          >
                            {props?.isSubmitting ? (
                              <>
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                  size="sm"
                                />
                                {t("PLEASE WAIT")}...
                              </>
                            ) : (
                              <>{edit.id ? t("UPDATE") : t("CREATE")}</>
                            )}
                          </button>
                          <ConfirmAlert
                            size={"sm"}
                            deleteFunction={props.handleSubmit}
                            hide={setShowAlert}
                            show={showAlert}
                            title={"Confirm UPDATE"}
                            description={
                              "Are you sure you want to update this!!"
                            }
                          />
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            )}
          </Formik>
        </CardComponent>
      </Col>
    </>
  );
};

export default CreateOutlet;
