import React from "react";

export const EmptyDataComponent = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 5,
        }}
      >
        <p>No Data Found!!</p>
      </div>
    </>
  );
};
