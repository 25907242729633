import { Form, FormLabel, InputGroup, Button, FormText } from "react-bootstrap";
import { useField } from "formik";
import { Fragment, memo } from "react";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";
import EditorToolbar, { formats, modules } from "./EditorToolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CircleMinus, Image, Plus, Upload } from "lucide-react";
import TextareaAutosize from "react-textarea-autosize";
import pdfIcon from "../assets/images/pdf.png";
import csvIcon from "../assets/images/csv.png";
import docIcon from "../assets/images/doc.png";
import xlsIcon from "../assets/images/xls.png";
import Files from "react-files";

const MyInput = ({
  formikProps,
  label,
  isRequired = false,
  customType,
  dateProps,
  selectProps,
  inputStyle,
  containerStyle,
  rightElement,
  min,
  max,
  quilHeight = "100px",
  helperText = "",
  ...props
}) => {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  async function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const tempObj = {
          fileName: file.name,
          file: event.target.result,
        };
        resolve(tempObj);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }
  // const { dateFormat, timeFormat } = useSelector(selectSettings);

  const NoDataInList = () => (
    <>
      {selectProps?.addMore && (
        <Button
          variant="outline-primary"
          className="w-100 mt-2"
          onClick={selectProps?.addMore}
        >
          <Plus size={15} /> Add New
        </Button>
      )}
      <div className="p-2">No Result</div>
    </>
  );

  const getFileTypeIcon = (fileName) => {
    const fileType = fileName.split(".").pop().toLowerCase();
    const icons = {
      pdf: <img width={20} style={{ objectFit: "cover" }} src={pdfIcon} />,
      csv: <img width={20} style={{ objectFit: "cover" }} src={csvIcon} />,
      doc: <img width={20} style={{ objectFit: "cover" }} src={docIcon} />,
      xls: <img width={20} style={{ objectFit: "cover" }} src={xlsIcon} />,
    };

    return icons[fileType] || <Image size={20} />;
  };

  const renderComponent = () => {
    switch (customType) {
      case "phone":
        return (
          <PhoneInput
            // countryCodeEditable={false}
            country={"in"}
            disableDropdown
            preferredCountries={["in"]}
            value={field.value}
            onChange={(phoneValue, country) => {
              formikProps.setFieldValue(field.name, `+${phoneValue}`);
            }}
            inputStyle={{
              width: "100%",
              background: "transparent",
              border: "0",
            }}
            {...props}
          />
        );

      case "select":
        const filteredValue = selectProps?.data?.filter((itm) =>
          props.multiple
            ? field?.value?.includes(itm.value)
            : itm?.value == field.value
        );
        return (
          <Select
            isMulti={props.multiple}
            menuPortalTarget={document.body}
            closeMenuOnSelect={!props?.multiple}
            value={props?.multiple ? filteredValue : filteredValue?.[0] || null}
            onChange={(selectedOption) => {
              if (selectProps?.onChange) {
                selectProps.onChange(selectedOption);
              }
              const value = props.multiple
                ? selectedOption.map((option) => option.value)
                : selectedOption?.value;
              formikProps.setFieldValue(field.name, value || "");
            }}
            options={selectProps?.data || []}
            isClearable
            classNamePrefix="react-select"
            isInvalid={error}
            placeholder="Select..."
            noOptionsMessage={() => <NoDataInList />}
            {...props}
          />
        );

      case "multiline":
        return (
          <TextareaAutosize
            rows={2}
            minRows={2}
            className="edit-textarea"
            value={field.value}
            onChange={(val) =>
              formikProps.setFieldValue(field.name, val.target.value)
            }
            {...props}
          />
        );

      case "editor":
        return (
          <Fragment>
            <EditorToolbar shortId={props?.shortId || field.name} />
            <ReactQuill
              style={{ height: quilHeight }}
              modules={modules(props?.shortId || field.name)}
              formats={formats}
              theme="snow"
              value={field.value}
              onChange={(val) => formikProps.setFieldValue(field.name, val)}
            />
          </Fragment>
        );

      case "fileUpload":
        return (
          <>
            <Files
              onChange={async (files) => {
                if (props.multiple) {
                  let tempFiles = [];
                  for (const file of files) {
                    const tempObj = await readFileAsync(file);
                    tempFiles.push(tempObj);
                  }
                  formikProps.setFieldValue(`${props.name}`, [
                    ...formikProps.values[props.name],
                    ...tempFiles,
                  ]);
                } else {
                  const tempObj = await readFileAsync(files[0]);
                  formikProps.setFieldValue(`${props.name}`, tempObj);
                }
              }}
              accepts={["image/png", ".pdf", ".csv", "audio/*"]}
              multiple={props.multiple}
              maxFileSize={10000000}
              clickable
              {...props}
            >
              <div
                className="bg-light text-center p-2"
                style={{ cursor: "pointer" }}
              >
                <Upload /> Upload Files
              </div>
            </Files>
            <div className="mt-2">
              {props.multiple
                ? formikProps.values[props.name]?.map((val, ind) => (
                    <div key={ind} className="d-flex justify-content-between">
                      <span>
                        {getFileTypeIcon(val.fileName)} {val.fileName}
                      </span>
                      <CircleMinus
                        className="text-danger cursor-pointer"
                        size={"20"}
                        onClick={() => {
                          formikProps.setFieldValue(
                            `${props.name}`,
                            formikProps.values[props.name].filter(
                              (_, idx) => idx !== ind
                            )
                          );
                        }}
                      />
                    </div>
                  ))
                : formikProps.values[props.name] && (
                    <div className="d-flex justify-content-between">
                      <span>
                        {getFileTypeIcon(
                          formikProps.values[props.name].fileName
                        )}{" "}
                        {formikProps.values[props.name].fileName}
                      </span>
                      <CircleMinus
                        className="text-danger cursor-pointer"
                        size={"20"}
                        onClick={() => {
                          formikProps.setFieldValue(`${props.name}`, null);
                        }}
                      />
                    </div>
                  )}
            </div>
          </>
        );

      default:
        return (
          <InputGroup>
            <Form.Control
              type={customType || "text"}
              isInvalid={error}
              {...field}
              {...props}
              min={min || 1}
            />
          </InputGroup>
        );
    }
  };

  return (
    <div style={containerStyle}>
      {label && (
        <FormLabel>
          {label} {isRequired && <span className="text-danger">*</span>}
        </FormLabel>
      )}
      {renderComponent()}
      {helperText && <FormText>{helperText}</FormText>}
      {error && <FormText className="text-danger">{meta.error}</FormText>}
    </div>
  );
};

export default memo(MyInput);
